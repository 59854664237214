import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"

interface ImageSliderProps {
  imageData: any[]
}

const ImageSilider = (props: ImageSliderProps): React.ReactNode => {
  const [currentImage, setCurrentImage] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setCurrentImage(value);
  }

  const imageData: IGatsbyImageData | undefined = props.imageData[currentImage-1];
  console.log("Current image nr", currentImage);
  console.log("mainimage data in component", imageData);

  return (
  <Stack>
    <GatsbyImage image={getImage(imageData)} alt="product image" fluid={imageData.childImageSharp.fluid} />
    <Pagination count={props.imageData.length} page={currentImage} onChange={handleChange} />
  </Stack>
  );
}

export default ImageSilider;