/*
Examples to send FB events
fbq('track', 'Purchase', {value: 12, currency: 'USD'}, {eventID: 'EVENT_ID'});
fbq('track', 'Lead', {}, {eventID: 'EVENT_ID'});

eventID can be left out
*/

type TrackType = 'AddPaymentInfo' | 'AddToCart' | 'AddToWishlist'
    | 'CompleteRegistration' | 'Contact' | 'CustomizeProduct'
    | 'Donate' | 'FindLocation' | 'InitiateCheckout' | 'Lead'
    | 'Purchase' | 'Schedule' | 'Search' | 'StartTrial'
    | 'SubmitApplication' | 'Subscribe' | 'ViewContent' | 'PageView'


export const track = (trackType: TrackType, options?: any) => {
    if (typeof window !== 'undefined' && 'fbq' in window) {
        if (options) {
            (window as any).fbq('track', trackType, options);
        } 
        else {
            (window as any).fbq('track', trackType)
        }
        return true
    } else {
        console.log("fb Won't track")
        return false
    }
}

export const gtmTrack = (event: string, buttonName?: string) => {
    if (typeof window !== 'undefined') {
        (window as any).dataLayer = (window as any).window.dataLayer || [];
        const data = (window as any).dataLayer
        
        data.push({   
            'event': event,
            'button_name': buttonName ? buttonName : '',
        });


        return true;
    } else {
        console.log("gtm Won't track")
        return false;
    }
}
 