import React, { useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonBase, Card, CardActions, CardContent, CardHeader, Chip, Grid, Link as MuiLink, Paper, Typography, styled } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';

import Seo from '../../components/seo';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import ImageSilider from '../../components/imageSlider';
import { orange, yellow } from '@mui/material/colors';
import StarIcon from '@mui/icons-material/Star';
import BuyWithPrimeButton from '../../components/BuyWithPrimeButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import BitsVideoMobile from '../../images/bit-holder/bit-short-video-1080x480.mp4';

import { getSrc } from "gatsby-plugin-image";
import BitsNavigation from '../../components/BitsNavigation';
import { gtmTrack, track } from '../../utils/tracking';

const OFF_PERCENT = 15

const onBuyClick = (event, url, button) => {
  event.preventDefault();
  track("InitiateCheckout");
  gtmTrack("InitiateCheckout", button);
  window.open(url, "_blank", "noopener");
}

const BuyButton = styled(MuiLink)(({ theme }) => ({
  height: 80,
  color: 'black',
  backgroundColor: theme.palette.primary.main,
  width: '80%',
  borderRadius: '20px',
  fontFamily: "Montserrat,sans-serif",
  fontWeight: 700,
  fontSize: '1.6rem',
  lineHeight: 1.75,
  letterSpacing: '0.02857em',
  textTransform: 'uppercase',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  marginBottom: '1rem',
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
    fontSize: '1.5rem',
  },
  '&:hover, &.Mui-focusVisible': {
    cursor: 'pointer',
    zIndex: 1,
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const CenteringGridItem = styled(Grid)(() => ({
  '&': {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
  }
})) as typeof Grid

const HeadList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '0px',
    marginRight: '0px',
  },
  '& li': {
    [theme.breakpoints.down('sm')]: {
      marginRight: '5px',
      fontSize: '18px',
    },
    textTransform: 'uppercase',
    fontSize: '40px',
    fontWeight: 600
  }
}))

const H1Title = styled(Typography)(({ theme }) => ({
  '&': {
    //fontSize: '2.5rem',
    //fontWeight: 600,
  },
})) as typeof Typography

const H2Title = styled(Typography)(({ theme }) => ({
  '&': {
    //fontSize: '2rem',
    //fontWeight: 600,
  },
})) as typeof Typography

const ReviewCard = ({ title, name, children }) => {
  return (
    <Box sx={{ minWidth: 275, marginBottom: '5px' }}>
      <Card variant="outlined">
        <CardContent>
          <Typography color="text.secondary">
            {name}
          </Typography>
          <FiveStars />
          <Typography color="text.secondary" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography>
            {children}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}

const OfferRealValue = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize: '1.2rem',
    fontWeight: 700,
  }
})) as typeof Typography

const OfferSave = styled(Typography)(({ theme }) => ({
  '&': {
    fontWeight: 700,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem'
  },
})) as typeof Typography

const OfferPrice = styled(Typography)(({ theme }) => ({
  '&': {
    fontWeight: 700, 
    fontSize: '2rem',
    marginBottom: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem'
  },
})) as typeof Typography

const OfferLastLine = styled(Typography)(({ theme }) => ({
  '&': {
    marginTop: '1.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    //fontSize: '1.5rem'
  },
})) as typeof Typography

const OfferList = styled("ul")(({ theme }) => ({
  listStyleType: 'none',
  [theme.breakpoints.down('sm')]: {
    paddingInlineStart: '0px',
    marginBlockEnd: '0px',
  }
}))

const BulletList = styled('ol')(({ theme }) => ({

}))

const BulletItem = styled('li')(({ theme }) => ({
  paddingBottom: '2rem',
  '&::marker': {
    fontWeight: 'bold',
  },
  [theme.breakpoints.down('sm')]: {
    paddingBottom: '0.1rem'
  }
}))

const OfferCardHeader = styled(CardHeader)(({theme}) => ({
  '&': {
    backgroundColor: orange[300],
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '5px'
  }
}))

const OfferCard2X = ({ theme }) => {
  return (
      <Card variant="outlined">
        <OfferCardHeader
        title="Essential Efficiency"
        titleTypographyProps={{color: 'text.secondary', fontWeight: 500 }}
        />

        <CardContent sx={{ textAlign: 'center' }}>
          <Typography color="text.secondary" sx={{ fontWeight: 600 }}>
          The Starter Pack
          </Typography>
            <OfferList>
              <BulletItem>12 x Bit Holders</BulletItem>
              <BulletItem>2 x Carabiners</BulletItem>
            </OfferList>
          <StaticImage
              src="../../images//bit-holder/bit-2x-main-1.jpg"
              alt="Enhance"
              loading="eager"
              height={500}
              quality={95}
              layout='constrained'
              formats={["auto", "webp", "avif"]}
            />
          <OfferRealValue>
            Time Saving Value: <span style={{ textDecoration: 'line-through' }}>$ 100</span>
          </OfferRealValue>
          <OfferSave color="#e93800">
            Save: $ 81
          </OfferSave>
          <OfferPrice>
            $ 18.99 
          </OfferPrice>
          <BuyButton 
            onClick={(event) => onBuyClick(event, 'https://www.amazon.com/Ultra-Handy-Keychain-Screwdriver-Improvement/dp/B0BGKWYVPC?maas=maas_adg_615745D1E2549C482D889A52C1EA0981_afap_abs&ref_=aa_maas&tag=maas', "BuyStarter")}
          >
            BUY NOW
          </BuyButton>
          <OfferLastLine>
            Fulfillment By Amazon. Prices apply for Amazon Business customers
          </OfferLastLine>
        </CardContent>
        <CardActions>
          
        </CardActions>
      </Card>

  )
}

const OfferCard4X = () => {
  return (

      <Card variant="outlined">
        <OfferCardHeader
        title="Enhanced Productivity"
        titleTypographyProps={{color: 'text.secondary', fontWeight: 500 }}
        />        
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography color="text.secondary" sx={{ fontWeight: 600 }}>
          The Professional's Choice
          </Typography>
            <OfferList>
              <BulletItem>24 x Bit Holders</BulletItem>
              <BulletItem>4 x Carabiners</BulletItem>
            </OfferList>
          <StaticImage
              src="../../images/bit-holder/bit-4x-main-1.jpg"
              alt="Enhance"
              loading="eager"
              height={500}
              quality={95}
              formats={["auto", "webp", "avif"]}
            />
          <OfferRealValue>
          Time Saving Value: <span style={{ textDecoration: 'line-through' }}>$ 200</span>
          </OfferRealValue>
          <OfferSave color="#e93800">
            Save: $ 170
          </OfferSave>
          <OfferPrice>
            $ 28.99
          </OfferPrice>
          <BuyButton 
            onClick={(event) => onBuyClick(event, 'https://www.amazon.com/Ultra-Handy-Keychain-Screwdriver-Improvement/dp/B0C6PSFH7K?maas=maas_adg_6D9289D3C70B0BD46A5300BF24DF3E6D_afap_abs&ref_=aa_maas&tag=maas',"BuyProfessional")}
          >
            BUY NOW
          </BuyButton>
          <OfferLastLine>
            Fulfillment By Amazon. Prices apply for Amazon Business customers
          </OfferLastLine>
        </CardContent>
      </Card>

  )
}

const OfferCard4XBest = () => {
  return (
      <Card variant="outlined" sx={{
        borderRadius: '8px',
        border: '10px solid rgb(250 151 8)'
      }}>
        <CardHeader
        sx={{ backgroundColor: orange[700], textAlign: 'center', textTransform: 'uppercase'}}
        title="Ultimate Performance"
        titleTypographyProps={{color: 'text.secondary', fontWeight: 800 }}
        />        
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography color="text.secondary" sx={{ fontWeight: 600 }}>
          The Master Carpenter's Pack
          </Typography>
            <OfferList>
              <BulletItem>24 x Bit Holders</BulletItem>
              <BulletItem>4 x Carabiners</BulletItem>
              <BulletItem>2 x 60mm Bar Extenders <Chip label="FREE Bonus" color="success"/></BulletItem>
              <BulletItem>1 x 100mm Bar Extender <Chip label="FREE Bonus" color="success"/></BulletItem>
            </OfferList>
          <StaticImage
              src="../../images//bit-holder/bit-4x-best-main-1.jpg"
              alt="Enhance"
              loading="eager"
              height={500}
              quality={95}
              formats={["auto", "webp", "avif"]}
            />
          <OfferRealValue>
          Time Saving Value: <span style={{ textDecoration: 'line-through' }}>$ 220</span>
          </OfferRealValue>
          <OfferSave color="#e93800">
            Save: $ 186
          </OfferSave>
          <OfferPrice>
            $ 29.99
          </OfferPrice>
          {/*<BuyButton 
            href="https://www.amazon.com/Ultra-Handy-Keychain-Screwdriver-Improvement/dp/B0BXLD8YV4?maas=maas_adg_9B4020521E7661638FC06395679A327B_afap_abs&ref_=aa_maas&tag=maas"
            target="_blank"
            rel="noopener"
      >*/}  
            <BuyButton
            onClick={(event) => onBuyClick(event, 'https://www.amazon.com/Ultra-Handy-Keychain-Screwdriver-Improvement/dp/B0BXLD8YV4?maas=maas_adg_9B4020521E7661638FC06395679A327B_afap_abs&ref_=aa_maas&tag=maas', "BuyMaster")}
            >
            BUY NOW
          </BuyButton>
          <OfferLastLine>
            Fulfillment By Amazon. Prices apply for Amazon Business customers
          </OfferLastLine>
        </CardContent>
      </Card>
  )
}



const FiveStars = () => {
  return (
    <Box>
      <StarIcon sx={{ color: yellow[800], fontSize: 30 }} />
      <StarIcon sx={{ color: yellow[800], fontSize: 30 }} />
      <StarIcon sx={{ color: yellow[800], fontSize: 30 }} />
      <StarIcon sx={{ color: yellow[800], fontSize: 30 }} />
      <StarIcon sx={{ color: yellow[800], fontSize: 30 }} />
    </Box>
  );
}

const QASection = () => {
  return (
    <Box>
      <Typography component="h2" variant="h2">
        Q & A
      </Typography>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        >
          What sizes and types of bits does the Screwdriver Bit Holder accommodate?
        </AccordionSummary>
        <AccordionDetails>
        Our bit holder is designed to be universally compatible with most standard 1/4" sized screwdriver bits. 
        It's perfect for accommodating a variety of flathead, Phillips, Torx, and hex bits, ensuring you have everything you need at your fingertips.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
        >
          How does the bit holder attach to a tool belt or backpack?
        </AccordionSummary>
        <AccordionDetails>
        The Screwdriver Bit Holder features a sturdy, carabiner with locking mechanism that allows you to easily and securely attach and detach it from your tool belt, backpack, or any loop.
        Once you have attached holder to your belt, you can take release needed bit with only one hand, that makes them easily available in tough situations.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3-content"
        id="panel3-header"
        >
          Is the Screwdriver Bit Holder durable enough for heavy-duty use?
        </AccordionSummary>
        <AccordionDetails>
        Absolutely! Our bit holder is constructed from high-quality, durable materials designed to withstand the rigors of daily use in any professional environment. It's built to last, even under tough conditions.
        To keep quality high we inspect and test each and every set after manufacturing to make sure all works as expected.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4-content"
        id="panel4-header"
        >
          What makes this bit holder different from other similar tools on the market?
        </AccordionSummary>
        <AccordionDetails>
        Our Screwdriver Bit Holder stands out due to its ergonomic design, 
        which not only provides ease of access and exceptional organization. It's designed specifically for tradespeople and handymans who need reliability and efficiency.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel5-content"
        id="panel5-header"
        >
         Is there a warranty or guarantee with the purchase of the Screwdriver Bit Holder?
        </AccordionSummary>
        <AccordionDetails>
        Yes, we stand behind our products with confidence. 
        The Screwdriver Bit Holder comes with a 30 day happiness guarantee which means if you are not completely satisfied with your purchase then we're not happy either and we really WANT to give your money back.
        Also if you hit any issues at all with your product just throw email at <b>suppor@forestjoker.com</b> and we get back to you as humanly possible and solve issues best possible way. 
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel6-content"
        id="panel6-header"
        >
         How do I maintain and clean the bit holder?
        </AccordionSummary>
        <AccordionDetails>
        Maintenance is simple. The bit holder can be wiped clean with a damp cloth. Avoid using harsh chemicals, and store it in a dry place to ensure it remains in top condition. 
        It doesn't hurt if you give drip of oil to moving parts time to time, just to make sure they move smoothly as possible.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel7-content"
        id="panel7-header"
        >
         Who is behind Ultra Handy brand?
        </AccordionSummary>
        <AccordionDetails>
        Ultra Handy™ is the proud trademark of Forest Joker LLC, a dynamo of a company rooted in the USA. While we hail from the land of the free, our fearless leader Mikko Keränen prefers the scenic vistas of Finland (and cold weather, crazy huh?). He's living it up Finnish-style, proving you can run an American company just as effectively from the land of saunas and reindeer!
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel8-content"
        id="panel8-header"
        >
         I have still question, can you help?
        </AccordionSummary>
        <AccordionDetails>
        Absolutely! Drop us email at <b>support@forestjoker.com</b> and we get back to you as soon as humanly possible.
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

const HeroVideo = styled("video")(({theme}) => ({
  width: '50vw',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
}))

export default function GetBitsKeychain({ data }) {
  //const [ isFormOpen, setFormOpen] = useState(false);

  const openForm = () => {
    //setFormOpen(true);
    window._klOnsite = window._klOnsite || [];
    window._klOnsite.push(['openForm', 'SE9QdZ']);
  }

  return (
    <Layout Navigation={<BitsNavigation/>}>
      <Grid container spacing={0}>
        <CenteringGridItem item xs={12}>
          <HeroVideo 
            controls
            autoPlay
            muted
            poster={getSrc(data.videoThumb)}
          >
            <source src={BitsVideoMobile} type="video/mp4" />

            <p>
              Sorry your browser doesn't support video so you miss this experience but read on, there is lot of interesting stuff on this page :)
            </p>
          </HeroVideo>
        </CenteringGridItem>
      </Grid>
      <Grid container spacing={0} rowSpacing={2}>
        <Grid item md={6} xs={12}>
          <Typography component="h1" variant="h1" sx={{ fontSize: '2rem'}}>
            Boost Your Earnings and Efficiency
            {/* Unlock Your Full Professional Potential with the Ultimate Screwdriver Bit Holder */}
          </Typography>
          <Typography component="h2" variant="h2" sx={{ fontSize: '1.5rem'}}>
            For Busy Carpenters, Never Waste a Minute or Lose a Bit Again—Immediately with Our Revolutionary Screwdriver Bit Holder
          </Typography>
          <HeadList>
            <BulletItem><span style={{color: '#d56e00'}}>1.</span> Boost</BulletItem>
            <BulletItem><span style={{color: '#d56e00'}}>2.</span> Enjoy</BulletItem>
            <BulletItem><span style={{color: '#d56e00'}}>3.</span> Profit</BulletItem>
          </HeadList>
        </Grid>
        <Grid item md={6} xs={12}>
          <ImageSilider
            imageData={
              [
                data.mainImage,
              ]
            } />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>
            Are you tired of wasting time searching for the right sized screwdriver bit on the job?
            Imagine a workday where every screwdriver bit you need is <b>right at your fingertips</b>, perfectly organized and easily accessible right from your tool belt.
            With our <b>revolutionary</b> Screwdriver Bit Holder in Keychain, that day is today.
          </Typography>
          <Typography>
            <b>Boost your productivity</b>, enhance your professional image, and increase your earnings with a simple addition to your toolkit.
            Designed for professionals like you, our bit holder ensures that you're always prepared, efficient, and ready to impress clients and colleagues alike.
          </Typography>
          <Typography>
            Don't just take our word for it — experience the difference for yourself and see why thousands of contractors, carpenters and electricians have already made the switch.
            Invest in better workflows, smarter time management, and a more <b>profitable</b> future.
          </Typography>
        </Grid>
        <CenteringGridItem item xs={12}>
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <BuyButton href="#buy-section">BUY NOW</BuyButton>
          </div>
        </CenteringGridItem>
        <Grid item md={12}>
          <Typography component="h3" variant="h3">Unlock Efficiency: Top Reasons Why the Ultra Handy Bit Holders in Keychain is a Game-Changer</Typography>
          <BulletList>
            <BulletItem>The Real Purpose Behind Tool Organization - Once you understand the importance of quick access, you'll dominate job sites with unmatched efficiency.</BulletItem>
            <BulletItem>Why Almost No One Saves Time Like This... and the secret to instantly accessing the right tool when you need it most.</BulletItem>
            <BulletItem>Two Brand New Features of the Ultimate Bit Holder - No matter who you are, once you use its quick-swap and secure-lock mechanisms, you'll streamline every project.</BulletItem>
            <BulletItem>The Exact Roadmap to Workday Efficiency - Here is where swift bit changes and clutter-free tool belts happen.</BulletItem>
            <BulletItem>The One Powerful Feature to Secure Bits better than even the most expensive tool belts can!</BulletItem>
            <BulletItem>How to Take Your Tools and Make Them Instantly Accessible - Once this happens, peak productivity will fall right into your lap.</BulletItem>
            <BulletItem>Tool Loss Explained - Once you master the use of our Screwdriver Bit Holder, you can save on replacement costs while laughing at the chaos of lost bits.</BulletItem>
            <BulletItem>The Disorganized Tool Belt Dilemma - How messiness hinders performance, even though you have great tools. (Here’s how you can sidestep this fate and boost your productivity).</BulletItem>
            <BulletItem>How to Find, Discover, and Use Your Screwdriver Bits in Seconds so you can finish jobs faster (And that’s only the second-best benefit; the best is how much smoother your day goes).</BulletItem>
            <BulletItem>The Secret of Doing Quick Repairs - Even if it's a makeshift fix, it ends up looking professional - better than those who take the long way.</BulletItem>
            <BulletItem>How to Get Into the Efficiency Zone so you can tackle any project that would otherwise slow you down.</BulletItem>
            <BulletItem>How to Double or Triple Your Project Handling Capacity at no risk by staying organized and ready.</BulletItem>
          </BulletList>
        </Grid>
        <Grid item md={12}>
          <StaticImage
              src="../../images/enhance-your-pro.jpg"
              alt="Enhance"
              loading="eager"
              height={600}
              quality={95}
              formats={["auto", "webp", "avif"]}
            />
        </Grid>
        <CenteringGridItem item xs={12}>
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <BuyButton href="#buy-section">I WANT BOOST</BuyButton>
          </div>
        </CenteringGridItem>
        <Grid item md={12}>
          <Typography component="h3" variant="h3"  sx={{ marginBottom: 0}}>Best choice for professionls</Typography>
          <Typography component="h4" variant="h4">tradespersons, carpenters, electricians, DIYs</Typography>
          <BulletList>
            <BulletItem>Boost Your Productivity Instantly - Say goodbye to wasted time searching for the right bit</BulletItem>
            <BulletItem>Increase Your Workday Satisfaction - Enjoy a clutter-free, frustration-free work environment</BulletItem>
            <BulletItem>Project Professionalism - Happy Customers, More Leads, More Repeat Business</BulletItem>
            <BulletItem>Save Time (and money). A lot - <b>2310 minutes</b> a year!</BulletItem>
            <BulletItem>Sustain Your Career with Comfort and Health - Reduces unnecessary strain and keeps your tools conveniently accessible</BulletItem>
            <BulletItem>Make the Smart Choice - Choose Efficiency, Choose Success</BulletItem>
          </BulletList>
        </Grid>
        <Grid item md={8} xs={12}>
          <Typography component="h3" variant="h3">Meet Jack: A Carpenter's Efficient Day</Typography>
          <Typography>
            Jack, a professional carpenter, begins his day early, eager to tackle the cabinetry for a new coffee shop. Armed with his <b>Ultimate Screwdriver Bit Holder</b> clipped securely to his belt, he's ready for a day of precise, <b>efficient work</b>.
          </Typography>
          <Typography>
            Gone are the days of rummaging through a cluttered toolbox. With the bit holder, Jack <b>quickly switches</b> between drill and driver bits right from his belt, speeding up his workflow. Installing hinges and adjusting doors, tasks that used to involve frustrating searches for the right bit, are now smooth and swift.
          </Typography>
          <Typography>
            Thanks to his new tool, Jack <b>enjoys</b> a rare, relaxed lunch break, having sped through the morning's tasks ahead of schedule. He finishes the day <b>early,</b> leaving the job site with everything in order, feeling less fatigued and proud of his <b>productivity boost.</b>
          </Typography>
          <Typography>
            <b>The Ultimate Screwdriver Bit Holder</b> isn't just a tool for Jack—it's a transformation in his workday, allowing him to <b>work smarter</b> and present a professional image that impresses clients and eases his day.
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <StaticImage
            src="../../images/carpenter1-mobile.jpg"
            alt="Carpenter at work"
            loading="eager"
            height={1080}

            quality={95}
            formats={["auto", "webp", "avif"]}
          />
        </Grid>
        <CenteringGridItem item xs={12}>
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <BuyButton href="#buy-section">BUY NOW</BuyButton>
          </div>
        </CenteringGridItem>
        <Grid item xs={12}>
          <Typography component="h2" variant="h2">
            What our customers say
          </Typography>
        </Grid>
        <Grid item xs={12} id="proof">
          <ReviewCard title="Makes work easier." name="gilda">
            It's nice to have all my crucial bits in one place.
          </ReviewCard>
          <ReviewCard title="Great to hook onto your tool belt" name="Gretchen Moeller">
            Hooks onto your toolbelt very nicely. Carry around dear bits, without having to dig through your bags.
          </ReviewCard>
          <ReviewCard title="Now I can carry ALL the bits!" name="Berq Berqelson">
            I've used OEM bit holders on my drills and drivers for years, and they've doubtless saved me countless hours of bit hunting as I work. But I'm frequently a slot or two shy of having all the bits I need for the task at hand. And because every tool I lay down is stolen by gremlins as soon as I turn my back, that's a big problem. These bit holders are worth every penny. They're pull-to-release style, which makes it easier to remove a bit than push style holders. They hold all bit sizes, including the stubby, 5/8"" ones. I pulled as hard as I could to remove one without moving the release sleeve, and the bit held firm. Upon pulling the sleeve, the bits drop free under their own weight. This is essential if you're going to use stubby bits, as they aren't long enough to get a good grip on with your fingers. The locking carabiners are nothing special, but are totally adequate for the job. The only complaint I could think of is that bits can't be shoved into the holders without first pulling the release sleeve, making one-handed operation a task requiring greater dexterity. Since these are lightweight aluminum instead of steel, their durability will have to be assessed after I've had a year or so to abuse them.
          </ReviewCard>
          <ReviewCard title="Bit holder" name="Teddy S Yamamoto">
            I liked the 'lock' feature. Upon inserting the bit, tug on the bit so as to lock it in place.
          </ReviewCard>
          <ReviewCard title="Awesome" name="Daren L. Dufloth">
            This is a great accessory to have
          </ReviewCard>
          <ReviewCard title="The bit organizer you never knew you needed!" name="Careful shopper">
            I picked this up because I thought it was an interesting take on bit organization. I am incredibly impressed. Having the ability to have multiple sizes of a torx bit on one key chain makes it super easy to find the right size to fit the screw. The fact that they are held together on a carabiner is also clever as it allows for attachment to a toolbag or even belt for mobile use.

            These are quality manufactured parts. The fit onto my Milwaukee bits is almost the exact same as the driver. Just enough clearance for them to pop in and out one handed. I was worried they would be too tight/too loose, but they are just right.

            I do wish you could choose the colors of the individual bit holders, I would have chosen all red. Although I do see the value in having multiple colors for different applications. Regardless I definitely recommend this product.
          </ReviewCard>
          <ReviewCard title="Handy for a working man" name="Chelsea R">
            My husband works in the construction field and every evening when he comes home he has a bunch of drillbits randomly laid out on my countertop so I purchases and hopes that they would keep the clutter of my counter, and they absolutely do. They're easy to use you can pop the drill bit and pop it right out no issues it's very simple. They stay in place and they are all held together on the keychain. It has made his workday much smoother, he doesn't have to dig for drillbits in his bag or his pockets. Perfect gift for him.
          </ReviewCard>
          <ReviewCard title="Clever solution. Everything on one ring. Takes up very little room in your toolbox" name="Rob M.">
            Finally, my miscellaneous hex sockets with standard ¼" hex shafts are organized on one ring (carabiner) and I no longer have to dig through my toolbox trying to find the correct size.

            The all aluminum mechanisms are super easy and quick to use, and I expect they'll last a very long time

            These bit holders really are great and take up very little room in your bag or tool box while at the same time keeping all the sizes you'll need - all on one ring. I love this solution.
          </ReviewCard>
        </Grid>
        <Grid item md={12} display="flex" justifyContent="center" alignItems="center">
          <StaticImage
            src="../../images/bit-holder/bit-enhance-productivity.jpg"
            alt="Enhance your productivity with bit holder in keychain"
            loading="eager"
            height={800}
            quality={95}
            formats={["auto", "webp", "avif"]}
            />
        </Grid>
        <CenteringGridItem item xs={12}>
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <BuyButton href="#buy-section">I WANT THIS</BuyButton>
          </div>
        </CenteringGridItem>
        <Grid item md={12}>
          <Typography component="h2" variant="h2">Think about what you will become when you have tool which...</Typography>
          <BulletList>
            <BulletItem><b>Boost Your Productivity Instantly:</b> Experience a revolutionary increase in efficiency from the moment you clip on our Screwdriver Bit Holder. Say goodbye to wasted time searching for the right bit and hello to smooth, uninterrupted work. Handle more jobs in less time and watch your productivity soar!</BulletItem>
            <BulletItem><b>Increase Your Workday Satisfaction:</b> Enjoy a clutter-free, frustration-free work environment. Our bit holder ensures your essential tools are always within reach, dramatically simplifying your workflow. Feel the satisfaction of a job well done, every day, with less hassle and more peace of mind.</BulletItem>
            <BulletItem><b>Project Professionalism That Wins Trust:</b> Impress clients with your organization and preparedness. The professional appearance you present with our bit holder builds confidence and trust, leading to more referrals and repeat business. Stand out as the expert you are, reliable and equipped for anything.</BulletItem>
            <BulletItem><b>Save Time (and money). A lot:</b> Think if you can save 10 minutes a day it's already 2310 minutes a year, that's 38,5 hours. One week extra time!
            If you charge $ 50 a hour it's <b>$ 1925</b> money saved (or earned as extra)! Our durable, ergonomic bit holder saves you money by protecting your bits from loss and damage, keep them easy to access
            and the time you save translates directly into increased earnings. Invest in your tools, <b>invest in your future.</b></BulletItem>
            <BulletItem><b>Sustain Your Career with Comfort and Health:</b> Designed with your well-being in mind, our bit holder reduces unnecessary strain and keeps your tools conveniently accessible. Enjoy a longer, healthier career with tools that support not just your work, but also your physical health.</BulletItem>
            <BulletItem><b>Make the Smart Choice:</b> Join the professionals who have already transformed their workday. With our Screwdriver Bit Holder, you're not just buying a tool; you're investing in a brighter, more successful future in your life.</BulletItem>
          </BulletList>
        </Grid>
        <CenteringGridItem item xs={12}>
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <BuyButton href="#buy-section">YES! BUY NOW</BuyButton>
          </div>
        </CenteringGridItem>
        <Grid item md={12}>
          <Typography component="h2" variant="h2">100% Happiness Guarantee</Typography>
          <StaticImage
            src="../../images/happy-carpenter.jpg"
            alt="100% Happiness Guarantee for carpenters"
            loading="eager"
            height={600}
            quality={95}
            formats={["auto", "webp", "avif"]}
          />
          <Typography>
          We've spent countless hours developing and refining our products - we KNOW you'll love them. 
          However, we understand this might be your first time buying from us.
          </Typography>
          <Typography>
          We want you to feel 100% comfortable in your purchase, so your order is covered in-full by our Happiness Guarantee.
          </Typography>
          <Typography>
          If you aren't happy with product you buy from us then we aren't happy and we INSIST that you let us know within 30 days of purchase by contacting us at 
          info@forestjoker.com or just return it to Amazon.
          </Typography>
          <Typography>
          We'll confirm your order, then provide you instructions to return your product to us, new, used, or broken. Once we receive it, we'll give you a 100% refund. 
          </Typography>
          <Typography>
          <b>If you're happy then we're happy. It's that simple!</b>
          </Typography>
          <Typography>
            Addition to above, if you hit any issue at all, we would looove to hear them and resolve as best as humanly possible. So pleeease <b>contact us at info@forestjoker.com</b>
            <br/>
            And our support will get back to your ASAP (We aim for FAST same day response).
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography component="h2" variant="h2" sx={{ color: "#f57415", textTransform: "uppercase" }}>Get Yours Today</Typography>
          <Typography>
            If we think about how much this little tool can <b>save</b> your time and money...<br/><span style={{ fontSize: '2rem', fontWeight: 600 }}>at least $ 1925</span> a year. <br/>Even our cheapest 2x6 Bit Holder Keychain set is worth of <b>$100</b> easily, and you'll get that value back fast!
          </Typography>
          <Typography>
            <b>BUT...</b> because we want to help as many as possible to achieve success in their life, we currently offer this with HUGE discount, starting from <span style={{ color: "#028319", fontWeight: 700 }}>$ 79 OFF</span>. 
          </Typography>
          <Typography>
            <b>This is stealing</b> but as USA based company we wan't to offer best success and service to our customers!
          </Typography>
          <Typography>
            You should act <b>fast</b>, this offer won't last long.
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography>
            Here is what happens when you click BUY button, you'll be redirected to our Amazon Product page where you just click <b>Add to Cart</b> or <b>Buy now</b> button and follow normal checkout process as you normally do when shopping in Amazon.
          </Typography>
          <Typography>
            Product is then shipped to you by FAST Amazon shipping. Easy!
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Grid container id="buy-section">
            <Grid item md={4}>
              <OfferCard2X/>
            </Grid>
            <Grid item md={4}>
              <OfferCard4XBest/>
            </Grid>
            <Grid item md={4}>
              <OfferCard4X />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <ReviewCard title="Great to hook onto your tool belt" name="gretchen moeller">
          Hooks onto your toolbelt very nicely. Carry around dear bits, without having to dig through your bags.
          </ReviewCard>
          <ReviewCard title="Glad I discovered these were a thing" name="Q">
          I feel like we spend an inordinate amount of time just looking for the correct bit for each job and having a couple of these around with every bit size immediately available has cut down on that time significantly. Easy to use and definitely a must have for anyone that works with tools.
          </ReviewCard>
        </Grid>
        <Grid item md={12} display="flex" justifyContent="center" alignItems="center">
        <StaticImage
            src="../../images/bit-holder/image3.jpeg"
            alt="bit holder sizing for electricians and carpenters"
            loading="eager"
            height={600}
            quality={95}
            formats={["auto", "webp", "avif"]}
          />
        </Grid>
        <Grid item md={12} id="faq">
          <QASection />
        </Grid>
        <CenteringGridItem item xs={12}>
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <BuyButton href="#buy-section">I WANT THIS</BuyButton>
          </div>
        </CenteringGridItem>
      </Grid>
    </Layout>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        storeUrl
      }
    }
    mainImage: file(relativePath: {eq: "bit-holder/bit-main-1.jpg"}) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        gatsbyImageData
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoThumb: file(relativePath: {eq: "bit-holder/bit-short-video-poster.jpg"}) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        gatsbyImageData
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: {eq: "bit-holder/image3.jpeg"}) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        gatsbyImageData
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: {eq: "bit-holder/image5.jpeg"}) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        gatsbyImageData
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: {eq: "bit-holder/image6.jpeg"}) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        gatsbyImageData
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: {eq: "bit-holder/image7.jpeg"}) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        gatsbyImageData
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`

export const Head = () => <Seo title="Unlock Your Full Professional Potential with the Ultimate Screwdriver Bit Holder" />

const openWaitlistForm = () => {
  window._klOnsite = window._klOnsite || [];
  window._klOnsite.push(['openForm', 'Y46cud']);
}
/*
Wait list form trigger code
window._klOnsite = window._klOnsite || [];
window._klOnsite.push(['openForm', 'Y46cud']);
*/
