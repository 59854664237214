import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button, { ButtonProps, ButtonPropsSizeOverrides } from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, navigate, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import * as stylesDashboard from '../styles/dashboard.module.css';
import Link from '@mui/material/Link';
import { LinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { orange, yellow } from '@mui/material/colors';

const pages = [
  {
    text: 'Proof',
    target: '#proof'
  },
  {
    text: 'FAQ',
    target: '#faq'
  },
];


const LogoLink = (props) => {
  return (
    <GatsbyLink {...props}
      style={{
        textDecoration: 'none',
        color: '#a84411'
      }}
    >
      Ultra Handy</GatsbyLink>
  )
}

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  marginLeft: '2rem',
  marginRight: '2rem',
  my: 2,
  color: 'white',
  display: 'block',
}))


const BitsNavigation = () => {
  const data = useStaticQuery(graphql`
    query SiteStoreUrlQuery {
      site {
        siteMetadata {
          storeUrl
        }
      }
    }
  `);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(null);
  };

  const handleNavItemClick = (target: string) => {
    setAnchorElNav(null);
    if (target) {
      console.log(target);
      navigate(target);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const BuyButton = styled(Link)(({ theme }) => ({
    height: 50,
    color: '#ff6000',
    backgroundColor: "#ffffff",
    maxWidth: '200px',
    borderRadius: '20px',
    fontFamily: "Montserrat,sans-serif",
    fontWeight: 800,
    fontSize: '1.6rem',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    padding: '10px',
    textDecoration: 'none',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters >
          <div className={stylesDashboard.containerLogo}>
            <StaticImage
              className={stylesDashboard.logo}
              src="../images/uh-logo-ai-v1-final.png"
              alt="Ultra Handy Logo"
              loading="eager"

              quality={95}
              formats={["auto", "webp", "avif"]}
              style={{ marginRight: '0.3rem' }}
            />
            {/* Medium screen logo text*/}
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 700,
                letterSpacing: '.3rem',
                textDecoration: 'none',
              }}
            >
              <LogoLink to="/" />
            </Typography>
            {/* small screen logo text */}
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontWeight: 700,
                fontSize: '1.0rem',
                letterSpacing: '.1rem',
                textDecoration: 'none',
                marginRight: '2px',
              }}
            >
              <LogoLink to="/" />
            </Typography>
          </div>
          {/* small screen content */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent:'right' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                left: '20px',
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.text} onClick={() => handleNavItemClick(page.target)}>

                    <Typography textAlign="center" color="primary">{page.text}</Typography>
                </MenuItem>
              ))}
              <MenuItem sx={{ fontWeight: 700 }}>
                <Link color="primary" underline="hover" onClick={() => handleNavItemClick("#buy-section")}>
                  <Typography textAlign="center">BUY</Typography>
                </Link>
              </MenuItem>
            </Menu>
          </Box>

          {/* Medium and up screen content*/}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', alignItems:'center' }}>
            {pages.map((page) => (
              <NavLink href={page.target}
                key={page.text}
              >
                {page.text}
              </NavLink>
            ))}
            <BuyButton href="#buy-section">BUY</BuyButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default BitsNavigation;
